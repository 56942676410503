/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import "../scss/styles.scss";

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
// import $ from 'jquery';

// Lazyload
import { lazyload } from "./modules/lazy";

lazyload.observe();
// CSS
 import "../css/owl.carousel.css";
 import "../css/owl.theme.css";

// Javascript
import { setFormEvents, initForm } from "./modules/search-form";
import { setRangeParticipants } from "./modules/filters";
import { getReviewsInfos } from "./modules/reviews";
export { getLogos } from "./modules/logos";

(function ($) {
  $(function () {

  });
})(jQuery);